if (!String.prototype.includes) {
    String.prototype.includes = function () {
        'use strict';
        return String.prototype.indexOf.apply(this, arguments) !== -1;
    };
}

function closeModal(modal) {
    modal.removeClass('open').fadeOut(500);

    jQuery('html, body').css('overflow', 'visible');
}

function openModal(modal) {
    jQuery('html, body').css('overflow', 'hidden');

    modal.addClass('open').fadeIn(500);
}

jQuery(document).ready(function ($) {

    $('.apply-contact').click(function (e) {
        e.preventDefault();
        $('a[href="#tab1"]').addClass('active');
        $('a[href="#tab2"]').removeClass('active');
        $('#second-tab-group #tab1').attr('style', 'display: block');
        $('#second-tab-group #tab2').attr('style', 'display: none');
    });

    $('.apply-brochure').click(function (e) {
        e.preventDefault();
        $('a[href="#tab2"]').addClass('active');
        $('a[href="#tab1"]').removeClass('active');
        $('#second-tab-group #tab2').attr('style', 'display: block');
        $('#second-tab-group #tab1').attr('style', 'display: none');
    });

    $('.modal-close').click(function (e) {
        var iframes = $(this).parent().find('.modal-body iframe');

        iframes.each(function () {
            this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        });

        e.preventDefault();
        var modal = $(this).parent().parent();
        closeModal(modal);
    });


    $(window).click(function (e) {
        var element = $(e.target);

        if (element.hasClass('modal')) {
            closeModal(element);
        }
    });

    $('.apply-now').click(function (e) {
        e.preventDefault();
        var modal = $('#' + $(this).data('target'));
        openModal(modal);
    });

});