jQuery(document).ready(function ($) {
$('.pageswitcher ul li.selector').click(function (e) {
    e.preventDefault();

    $(this).toggleClass('open');
    $('.pageswitcher ul li:not(.selector)').stop().slideToggle();

});
});

