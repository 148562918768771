jQuery(document).ready(function ($) {

    if (!!jQuery('#sub-navigation').offset() && jQuery(window).width() >= 980) {
        var stickyTop = jQuery('#sub-navigation').offset().top;
        jQuery(window).scroll(function () {
            var windowTop = jQuery(window).scrollTop();
            if (stickyTop < windowTop) {
                jQuery('#sub-navigation').addClass('stickybar');
            } else {
                jQuery('#sub-navigation').removeClass('stickybar');
            }
        });
    }

});