jQuery(document).ready(function ($) {
    $('.js-menu-toggle').on('click', function () {
        $('body').toggleClass('is-menu-on');
    });

    $('.open-sub-menu').click(function () {
        var parent = $(this).closest(' .menu-item');
        parent.toggleClass('visible');
    });
});
