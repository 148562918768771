jQuery(document).ready(function () {
    jQuery('.filter-toggle').on('click', function () {
        jQuery('body').toggleClass('filter-on');
    });
});

jQuery(document).ready(function () {
    jQuery('.filter-close').on('click', function () {
        jQuery('body').toggleClass('filter-on');
    });
});

jQuery(document).ready(function () {
    jQuery('.view-filters-results').on('click', function () {
        jQuery('body').toggleClass('filter-on');
    });
});

