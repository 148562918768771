jQuery(document).ready(function () {
    jQuery('#view-selection a').click(function() {
        var target = jQuery(this).data('target');
        var classes = jQuery(this).data('class');

        jQuery(target).attr('class', classes);

        jQuery('#view-selection .active').removeClass();
        jQuery(this).parent().addClass('active');
    });
});
