jQuery(document).on('click', 'a[href^="#"]', function(e) {
    e.preventDefault();

    var href = jQuery(this).attr('href');

    if (href.length === 1) {
        return;
    }

    var id = jQuery(href);

    if (id.length === 0) {
        return;
    }

    var offset = id.offset().top;
    offset -= 32;

    if (jQuery('body').hasClass('admin-bar')) {
        offset -= 32;
    }

    if (jQuery(id).hasClass('ui-tabs-panel')) {
        offset -= 100;
    }

    jQuery('body, html').animate({scrollTop: offset});
});
