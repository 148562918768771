jQuery(document).ready(function ($) {
    if ($(window).outerWidth() < 767) {

        document.body.classList += ' js';

        $('.js-read-more-toggle').on('click', function(){
            this.previousElementSibling.classList.toggle('is-expanded');
            this.classList.toggle('show-less');
        });
    }
});