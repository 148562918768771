jQuery(document).ready(function() {
    jQuery('#facetwp-sorting').hide();

    jQuery('#filter-sorting a').click(function() {

        jQuery('#filter-sorting li').removeClass('active');
        jQuery(this).parent().addClass('active');

        var target = jQuery(this).data('target');
        var value = jQuery(this).data('value');

        jQuery(target).val(value).trigger('change');
    });
});
