jQuery(document).ready(function ($) {
    var searchContainer = $('#search-container');

    searchContainer.find('.fa-search').click(function () {
        searchContainer.find('.search-overlay').stop().fadeIn();
        $('body').addClass('search-open');
        $('.search-input').focus();
        $('html,body').css('overflow', 'hidden');
    });

    searchContainer.find('.fa-times').click(function () {
        nfc_closeSearchContainer();
    });

    $(document).keyup(function (e) {
        if (e.keyCode === 27) { //Escape pressed
            nfc_closeSearchContainer();
        }
    });

    function nfc_closeSearchContainer ()
    {
        searchContainer.find('.search-overlay').stop().fadeOut();
        $('body').removeClass('search-open');
        $('html,body').css('overflow', 'auto');
    }
});
